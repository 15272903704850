import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Observable } from 'rxjs';
import {
  filter,
  map,
  shareReplay,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import { PatientSelectors, ProfileSelectors } from '@app/core';
import { FeatureFlagNames } from '@app/core/feature-flag/shared/feature-flag.type';
import { LaunchDarklyService } from '@app/core/launch-darkly/launchdarkly.service';
import { MedicationRegimen } from '@app/modules/medications/shared/medications.type';
import { Prescriber } from '@app/modules/shared-rx/prescriber-credential.type';
import {
  numberOfValidPrescribers,
  freeTextLengthMax,
} from '@app/modules/shared-rx/utils';
import { Todo } from '@app/modules/todo/shared/todo.type';
import { TodoActions } from '@app/modules/todo/store/todo.actions';
import { TodoSelectors } from '@app/modules/todo/store/todo.selectors';
import { DropdownItem } from '@app/shared';
import { filterTruthy } from '@app/utils';

import { RenewalForm } from './../../shared/renewal-form';
import { RenewalFormService } from '../../shared/renewal-form.service';
import { isValidPrescriber } from '../../shared/renewal-utils';
import { RenewalGuard } from '../../shared/renewal.guard';
import { Renewal, RenewalClass } from '../../shared/renewals.type';
import { RenewalActions, RenewalSelectors } from '../../store';
import { PatientMedicationAttachmentsServiceFactory } from '@app/modules/medications/shared/services/patient-medication-attachment.service';

@Component({
  selector: 'omg-renewal-item-detail',
  templateUrl: './renewal-item-detail.component.html',
  styleUrls: ['./renewal-item-detail.component.scss'],
})
export class RenewalItemDetailComponent implements OnInit, OnDestroy {
  cartComplete$: Observable<boolean>;
  renewal$: Observable<Renewal>;
  todo$: Observable<Todo>;
  form: RenewalForm;
  needsPediatricVitals: boolean;
  numberOfValidPrescribers: number;
  regimenEditingInProgress: boolean;
  isProvider: boolean;
  dispensableRestrictedControlledSubstance: boolean;
  userCanPrescribe: boolean;
  pediatricVitalsEnabled$: Observable<boolean>;
  longSig: boolean;

  sortedValidPrescribers$: Observable<Prescriber[]>;
  credentialsLoading$: Observable<boolean>;
  credentialItems$: Observable<DropdownItem[]>;

  problemToMedsLinkingEnabled: boolean;
  attachedProblemIds$: Observable<number[]>;

  constructor(
    private renewalSelectors: RenewalSelectors,
    private route: ActivatedRoute,
    private todoActions: TodoActions,
    private todoSelectors: TodoSelectors,
    private renewalFormService: RenewalFormService,
    private renewalActions: RenewalActions,
    private profileSelectors: ProfileSelectors,
    private renewalGuard: RenewalGuard,
    private patientSelectors: PatientSelectors,
    private launchDarklyService: LaunchDarklyService,
    private patientMedicationServiceFactory: PatientMedicationAttachmentsServiceFactory,
  ) {}

  ngOnInit(): void {
    this.renewalGuard
      .canActivate(this.route.snapshot)
      .pipe(
        filterTruthy(),
        tap(() => this.setupSelectors()),
      )
      .subscribe();

    this.problemToMedsLinkingEnabled =
      this.launchDarklyService.variation<number>(
        FeatureFlagNames.problemToMedsLinkingPhases,
        0,
      ) >= 3;
  }

  get disabled(): boolean {
    return (
      !this.form ||
      this.form.model.saving ||
      this.regimenEditingInProgress ||
      (!this.userCanPrescribe && this.dispensableRestrictedControlledSubstance)
    );
  }

  get disabledApproval(): boolean {
    return (
      this.disabled ||
      !this.form.valid ||
      !this.isProvider ||
      this.needsPediatricVitals ||
      this.longSig
    );
  }

  ngOnDestroy(): void {
    this.form?.unsubscribe();
  }

  setRegimenEditingInProgress(editing: boolean): void {
    this.regimenEditingInProgress = editing;
  }

  updatePediatricVitalsStatus(hasVitals: boolean): void {
    this.needsPediatricVitals = !hasVitals;
  }

  regimenUpdate(medicationRegimen: MedicationRegimen): Observable<Renewal> {
    const changes = {
      medicationRegimenId: medicationRegimen.id,
      className: this.form.renewal.className,
      medicationRegimen,
    };
    this.renewalActions.update({ id: this.form.renewal.id, changes });

    return this.renewalSelectors.getById(this.form.renewal.id);
  }

  private setupSelectors(): void {
    this.renewal$ = this.route.paramMap.pipe(
      map((params: ParamMap) => +params.get('id')!),
      tap((id: number) =>
        this.renewalActions.updateCartState({ currentRenewalId: id }),
      ),
      switchMap((id: number) => this.renewalSelectors.getById(id)),
      filter(renewal => !!renewal),
      tap(renewal => {
        this.numberOfValidPrescribers = numberOfValidPrescribers(renewal);
        this.credentialItems$ =
          this.renewalSelectors.credentialDropdownItemsFor(renewal.id);
        this.sortedValidPrescribers$ =
          this.renewalSelectors.sortedValidPrescribers(renewal.id);
        this.longSig =
          renewal.medForDisplay.instructions.length > freeTextLengthMax;
        this.dispensableRestrictedControlledSubstance =
          renewal.dispensableRestrictedControlledSubstance;
      }),
      shareReplay(1),
    );

    this.renewal$
      .pipe(
        withLatestFrom(this.profileSelectors.profileId),
        take(1),
        tap(
          ([renewal, profileId]) =>
            (this.userCanPrescribe = isValidPrescriber(
              profileId,
              renewal.validPrescribers,
            )),
        ),
        tap(
          ([renewal, profileId]) =>
            (this.form = this.renewalFormService.buildForm(renewal, profileId)),
        ),
        tap(([renewal]) => this.loadAndSetTodo(renewal.id, renewal.className)),
        tap(([renewal]) => this.loadProblemAttachments(renewal)),
      )
      .subscribe();

    this.profileSelectors
      .hasRole('provider')
      .pipe(
        take(1),
        tap(isProvider => {
          this.isProvider = isProvider;
        }),
      )
      .subscribe();

    this.pediatricVitalsEnabled$ = this.patientSelectors.isMinor;
    this.cartComplete$ = this.renewalSelectors.cartComplete;
    this.credentialsLoading$ =
      this.renewalSelectors.prescriberCredentialsLoading;
  }

  private loadAndSetTodo(id: number, className: RenewalClass): void {
    this.todoActions.loadTodoByEntity(id, className);
    this.todo$ = this.todoSelectors.todoByEntity(id, className);
  }

  private loadProblemAttachments(renewal: Renewal): void {
    if (renewal.patientMedicationId) {
      const patientMedicationService =
        this.patientMedicationServiceFactory.service(
          renewal.patientMedicationId,
        );

      this.attachedProblemIds$ =
        patientMedicationService.problemAttachments$.pipe(
          map(attachments =>
            attachments.map(attachment => attachment.problemId),
          ),
        );
    }
  }
}
